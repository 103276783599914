<template>
  <Layout>
    <div class="container">
      <div id="signupForm" class="container is-fluid">
        <h1>
          <span v-if="!edit">Create a New Group</span>
          <span v-else>Edit Group</span>
        </h1>
        <form @submit="submitForm($event)" novalidate>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="company">Company</label>
              <div class="control">
                <div class="select">
                  <select
                    v-model="company"
                    ref="company"
                    name="company"
                    class="input"
                    :class="{ 'is-danger': error.company }"
                    required="required"
                  >
                    <option
                      v-for="(company, index) in companies"
                      :key="index"
                      :value="company"
                    >
                      {{ company.name }}
                    </option>
                  </select>
                </div>
              </div>
              <p class="help" :class="{ 'is-danger': error.company }">
                {{ error.company }}
              </p>
            </div>
          </div>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="name">Name</label>
              <div class="control">
                <input
                  v-model="name"
                  ref="name"
                  name="name"
                  placeholder="Group name"
                  type="text"
                  class="input"
                  :class="{ 'is-danger': error.name }"
                  required="required"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.name }">
                {{ error.name }}
              </p>
            </div>
          </div>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="description">Description</label>
              <div class="control">
                <input
                  v-model="description"
                  ref="description"
                  name="description"
                  placeholder="Group Description"
                  type="text"
                  class="input"
                  :class="{ 'is-danger': error.description }"
                  required="required"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.description }">
                {{ error.description }}
              </p>
            </div>
          </div>

          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="users">Users</label>
              <div class="control">
                <users-list
                  :current-company="company"
                  :current-users="users"
                  v-on:selected:users:changed="updateUsers"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.users }">
                {{ error.users }}
              </p>
            </div>
          </div>
          <p v-if="error.general" class="help is-danger">{{ error.general }}</p>
          <button
            type="submit"
            class="button is-fullwidth is-primary text-capitalize"
          >
            <span v-if="!edit">Add Group</span>
            <span v-else>Update</span>
          </button>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';
import UsersList from '../../components/globals/UsersList.vue';

export default {
  name: 'newGroup',
  components: {
    Layout: () => import('@/router/layouts/Main'),
    UsersList
  },
  data() {
    return {
      error: {
        general: null,
        name: null,
        description: null,
        company: null,
        users: null,
      },
      name: '',
      description: '',
      company: {},
      users: [],
      companies: [],
    };
  },
  computed: {
    edit() {
      return this.$router.currentRoute?.params?.group || false;
    },
    isAdmin() {
      return $cookies.get($formsConfig.core.cookieName).role === 'admin';
    }
  },
  async beforeMount() {
    // Fetch user info.
    let userCompanyId = $cookies.get($formsConfig.core.cookieName).company;
    // Fetch the list of the companies.
    const response = await axios.get($formsConfig.core.api.companies);
    this.companies = response.data || {};
    this.company = this.companies.find(company => {
      return company._id === userCompanyId;
    });
   // fill form in case it is an instance to edit.
    if (this.edit) {
      const group = this.$router.currentRoute.params.group;
      this.name = group.name;
      this.description = group.description;
      this.company = this.companies.find(
        item => item._id === group.company._id
      );
      group.users.map(user => {
        this.users.push(user.id);
      });
    }
  },
  methods: {
    /**
     * This function performs the data validation and the
     * data submission if the form is valid.
     * @param {Event} evt The event will be used to cancel the
     * submission in case the form is invalid.
     * @returns boolean
     */
    async submitForm(evt) {
      // Cancel the default action for the form as we will send an XHR request.
      evt.preventDefault();
      if (this.validateForm()) {
        const group = {
          name: this.name,
          description: this.description,
          company: this.company._id,
          users: this.users,
        };
        if (!this.edit) {
          //Axios add a new group
          try {
            const response = await axios.post(
              $formsConfig.core.api.groups,
              group
            );
            if (response.status === 200) {
              this.$router.push('/groups');
            }
          } catch (e) {
            this.error.general =
              e?.response?.data?.message || 'Something went wrong while trying to create the group.';
          }
        } else {
          //Axios update a group
          const groupId = this.$router.currentRoute.params.group._id;
          try {
            const response = await axios.put(
              $formsConfig.core.api.groups + groupId,
              group
            );
            if (response.status === 200) {
              this.$router.push('/groups');
            }
          } catch (e) {
            this.error.general =
              e?.response?.data?.message || 'Something went wrong while trying to update the group.';
          }
        }
      }
    },
    validateForm() {
      // Clear validations.
      this.error = {
        general: null,
        email: null,
        name: null,
        company: null,
        users: null,
      };
      let isValid = true;
      for (const key in this.$refs) {
        if (this.$refs.hasOwnProperty(key)) {
          const field = this.$refs[key];
          isValid = field.checkValidity();
          if (!isValid) {
            this.error[key] = field.validationMessage;
          }
        }
      }
      if (this.users.length === 0) {
          this.error.users = 'Users list should not be empty';
          isValid = false;
      }
      return isValid;
    },
    updateUsers(newSelectedUsers) {
      this.users = newSelectedUsers;
    },
  }
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  @extend %fieldContainer;
}

.is-fluid {
  align-items: center;
  background: $background-panels;
  border-radius: $control-radius;
  h1 {
    margin-bottom: 30px;
    text-align: center;
  }
}

#signupForm {
  padding: 15px 250px 15px 250px;
  margin: 5px 98px 0 92px;
  .select {
    width: 100%;
  }
}

.link {
  color: $color-link-text;
  text-align: right;
}
</style>
